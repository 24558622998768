import React, { useState, useEffect } from "react";
import Masonry from 'react-masonry-css';
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";

function Dashboard({ user, storage, db }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionLevel, setSubscriptionLevel] = useState(0);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [failedImages, setFailedImages] = useState(new Set());

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setSubscriptionLevel(userData.subscriptionLevel || 0);
        setUsername(userData.username || "");
        await fetchUserImages(userDocRef);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
      setErrorMessage("Failed to load user data. Please try again.");
    }
  };

  const fetchUserImages = async () => {
    try {
      // Fetch saves made by the user
      const savesRef = collection(db, "saves");
      const savesQuery = query(savesRef, where("userId", "==", user.uid));
      const savesSnapshot = await getDocs(savesQuery);

      const imageIds = savesSnapshot.docs.map((doc) => ({
        saveId: doc.id, // Save the document ID of the save record
        imageId: doc.data().imageId,
        timestamp: doc.data().timestamp,
      }));

      // Fetch images based on image IDs
      const imageDocs = await Promise.all(
        imageIds.map((item) => getDoc(doc(db, "images", item.imageId)))
      );

      const userImages = [];
      for (let i = 0; i < imageDocs.length; i++) {
        const imageDoc = imageDocs[i];
        if (imageDoc.exists()) {
          userImages.push({
            id: imageDoc.id,
            saveId: imageIds[i].saveId, // Include saveId for deletion
            timestamp: imageIds[i].timestamp,
            ...imageDoc.data(),
          });
        }
      }

      // Sort images by timestamp in descending order
      const sortedImages = userImages.sort(
        (a, b) => b.timestamp.toMillis() - a.timestamp.toMillis()
      );

      setImages(sortedImages);
    } catch (error) {
      console.error("Error fetching user images:", error);
      setErrorMessage("Failed to load user images. Please try again.");
    }
  };

  const handleDeleteImage = async (saveId, imageId) => {
    try {
      // Delete the save record from the 'saves' collection
      const saveDocRef = doc(db, "saves", saveId);
      await deleteDoc(saveDocRef);

      // Update the image's save count
      const imageRef = doc(db, "images", imageId);
      const imageDoc = await getDoc(imageRef);
      if (imageDoc.exists()) {
        const currentSaveCount = imageDoc.data().saveCount || 0;
        await updateDoc(imageRef, {
          saveCount: Math.max(0, currentSaveCount - 1) // Ensure count doesn't go below 0
        });
      }

      // Update the local state to remove the deleted image
      setImages((prevImages) => prevImages.filter((image) => image.saveId !== saveId));

      setSnackbar({ open: true, message: 'Image unsaved successfully', severity: 'success' });
    } catch (error) {
      console.error("Error deleting image:", error);
      setSnackbar({ open: true, message: 'Failed to unsave image. Please try again.', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mb: 2 }}>
        Dashboard
      </Typography>

      {errorMessage && (
        <Typography color="error" gutterBottom>
          {errorMessage}
        </Typography>
      )}

      <Paper sx={{ p: 3, mb: 6, borderRadius: 2, boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)' }} elevation={0}>
        <Typography variant="h6" gutterBottom mb={2}>
          Your Saved Images
        </Typography>
        <Box>
          <Masonry
            breakpointCols={{
              default: 3,
              1100: 2,
              700: 1
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {images
              .filter(image => !failedImages.has(image.id))
              .map((image) => (
                <Box
                  key={image.id}
                  sx={{
                    mb: 3,
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      overflow: 'hidden',
                      borderRadius: 2,
                      backgroundColor: 'background.paper',
                      position: 'relative',
                      '&:hover': {
                        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
                      }
                    }}
                  >
                    <img
                      src={image.imageUrl}
                      alt={image.name || "User image"}
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                        borderRadius: "8px"
                      }}
                      onError={() => setFailedImages(prev => new Set([...prev, image.id]))}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage(image.saveId, image.id)}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 1)',
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Paper>
                </Box>
              ))}
          </Masonry>
        </Box>
        {images.length === 0 && (
          <Typography>No images found.</Typography>
        )}
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Dashboard;
