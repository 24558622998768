import React from 'react';
import { Box, Typography, Link } from '@mui/material';

function Footer() {
  return (
    <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#eee' }}>
      <Typography variant="body2" color="text.secondary" align="center">
        © {new Date().getFullYear()} Wandering Ghost, LLC. All rights reserved.
        {' | '}
        <Link color="inherit" href="/about">
          About
        </Link>
        {' | '}
        <Link color="inherit" href="/terms">
          Terms
        </Link>
        {' | '}
        <Link color="inherit" href="/privacy">
          Privacy
        </Link>
        <div style={{ display: 'none' }}>
          {' | '}
          <Link color="inherit" href="https://forms.gle/dJod8Rx9BPzz726h8">
          🚀 Feedback
          </Link>
        </div>
      </Typography>
    </Box>
  );
}

export default Footer;
