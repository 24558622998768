import React from 'react';

function PaymentSuccess() {
  return (
    <div className="container mt-5">
      <h1>Payment Successful!</h1>
    </div>
  );
}

export default PaymentSuccess;
