import React from 'react';
import { Typography, Box, List, ListItem, ListItemText } from '@mui/material';

function Terms() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">
        bursts Terms of Service
      </Typography>

      <Typography variant="body1" mb={2} mt={2}>
        Welcome to bursts! Your platform for sharing and exploring interesting content you discover online. Before you dive in, please take a moment to read our Terms of Service. By using bursts, you agree to these terms, which ensure a fun, engaging, and respectful experience for everyone.
      </Typography>

      <Typography variant="h6" mb={2}>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" mb={2}>
        By accessing or using bursts.us ("the Site"), operated by Wandering Ghost, LLC, you agree to be bound by these Terms of Service ("Terms"). If you do not agree with any of these Terms, please do not use the Site.
      </Typography>

      <Typography variant="h6" mb={2}>
        2. Your Use of bursts
      </Typography>
      <Typography variant="body1" mb={0}>
        At bursts, we celebrate online discovery! You are free to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Share interesting content you find online using our Chrome browser addon." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Explore a diverse world of web discoveries shared by other users." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Build and personalize a profile reflecting your interests and findings." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Connect with other curious minds in the community." />
        </ListItem>
      </List>
      <Typography variant="body1">
        However, to keep the platform fun and safe for everyone, you agree not to:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Share any content that is illegal, abusive, defamatory, or otherwise harmful." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Impersonate others or misrepresent yourself in any way." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Disrupt or interfere with the security or integrity of the Site." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Use the Chrome browser addon to collect or share personal information without consent." />
        </ListItem>
      </List>

      <Typography variant="h6" mb={2}>
        3. Content and NSFW Settings
      </Typography>
      <Typography variant="body1" mb={2}>
        When you share content on bursts, you retain ownership of your discoveries. By posting, you grant bursts a non-exclusive, royalty-free license to display, distribute, and promote the content as part of the Site's normal operations. We will not sell or commercialize your shared content without your permission.
      </Typography>
      <Typography variant="body1" mb={2}>
        bursts provides two important user-level toggles to manage content:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="1. Profile Content Toggle: This controls whether your profile can contain NSFW (Not Safe For Work) content." />
        </ListItem>
        <ListItem>
          <ListItemText primary="2. Viewing Preference Toggle: This determines whether you want to see NSFW content shared by other users when you're logged in." />
        </ListItem>
      </List>
      <Typography variant="body1" mb={2}>
        You are responsible for using these toggles appropriately and understanding that they affect your experience on the platform. bursts reserves the right to moderate and remove content that violates our community guidelines, regardless of toggle settings.
      </Typography>

      <Typography variant="h6" mb={2}>
        4. Community Guidelines
      </Typography>
      <Typography variant="body1" mb={0}>
        bursts is a space for positivity and artistic expression. To maintain a healthy community:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="- Respect others' work and creations." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- Provide constructive feedback, not harmful or hateful comments." />
        </ListItem>
        <ListItem>
          <ListItemText primary="- If you like it, share it! But always give credit where credit is due." />
        </ListItem>
      </List>

      <Typography variant="h6" mb={2}>
        5. Profile and Account Security
      </Typography>
      <Typography variant="body1" mb={2}>
        You are responsible for safeguarding your bursts profile. Keep your login information secure and notify us immediately if you suspect unauthorized use of your account.
      </Typography>

      <Typography variant="h6" mb={2}>
        6. Termination
      </Typography>
      <Typography variant="body1" mb={2}>
        Wandering Ghost, LLC reserves the right to suspend or terminate any account that violates these Terms or for any reason, at its discretion.
      </Typography>

      <Typography variant="h6" mb={2}>
        7. Changes to Terms
      </Typography>
      <Typography variant="body1" mb={2}>
        We may update these Terms from time to time. If we do, we'll notify you of any changes. Continued use of the Site after changes means you accept the new Terms.
      </Typography>

      <Typography variant="h6" mb={2}>
        8. Liability Disclaimer
      </Typography>
      <Typography variant="body1" mb={2}>
        bursts is provided "as is," and Wandering Ghost, LLC makes no warranties or guarantees about the availability or quality of the service. We are not liable for any content shared on the Site, user interactions, or disruptions in service.
      </Typography>

      <Typography variant="h6" mb={2}>
        9. Contact Information
      </Typography>
      <Typography variant="body1" mb={2}>
        bursts is created and operated by Wandering Ghost, LLC. For any questions or concerns, including issues with the Chrome browser addon or content moderation, you can contact us at:
      </Typography>
      <Typography variant="body1" mb={2}>
        Wandering Ghost, LLC<br />
        PO Box 362<br />
        Montague, MI, 49437<br />
        Email: wanderingghostllc@gmail.com
      </Typography>

      <Typography variant="h6" mb={2}>
        10. Governing Law
      </Typography>
      <Typography variant="body1" mb={2}>
        These Terms are governed by the laws of the state of Michigan, without regard to its conflict of law principles.
      </Typography>

      <Typography variant="body1" mb={2}>
        By using bursts and our Chrome browser addon, you're agreeing to all the above. Enjoy your time discovering, sharing, and exploring the web with our community!
      </Typography>
    </Box>
  );
}

export default Terms;

