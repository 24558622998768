import React, { useState, useEffect, useCallback } from "react";
import Masonry from "react-masonry-css";
import burstHowTo from "./images/bursts-howto.png";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  styled,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from "@mui/material";
import LoginButton from "./components/LoginButton";
import { getAuth } from "firebase/auth";
import {
  getDocs,
  query,
  orderBy,
  limit,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
  doc,
  where,
  updateDoc,
  increment,
} from "firebase/firestore";
import { Link } from "react-router-dom";

const ColorfulPaper = styled(Paper)(({ theme }) => ({
  background: "linear-gradient(135deg, #000 0%, #333 100%)",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(6),
  color: "white",
  marginBottom: theme.spacing(4),
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.15)",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root": {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 2),
    textTransform: "none",
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const Home = ({ storage, user, db }) => {
  const auth = getAuth();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [burstsImages, setburstsImages] = useState(new Set());
  const [feedType, setFeedType] = useState("latest");
  const [followingUsers, setFollowingUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [showNSFW, setShowNSFW] = useState(false);
  const [failedImages, setFailedImages] = useState(new Set());

  useEffect(() => {
    if (db && user) {
      fetchFollowingUsers();
      fetchUserPreferences();
    }
  }, [db, user]);

  useEffect(() => {
    if (db) {
      fetchAllImages();
      fetchActiveUsers();
    }
  }, [db, user, feedType, followingUsers, showNSFW]);

  const fetchFollowingUsers = async () => {
    if (!db || !user) return;
    try {
      const followingRef = collection(db, "users", user.uid, "following");
      const followingSnapshot = await getDocs(followingRef);
      const followingUserIds = followingSnapshot.docs.map(
        (doc) => doc.data().userId
      );
      setFollowingUsers(followingUserIds);
    } catch (error) {
      console.error("Error fetching following users:", error);
    }
  };

  const fetchUserPreferences = async () => {
    if (!db || !user) return;
    try {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        setShowNSFW(userDoc.data().showNSFW || false);
      }
    } catch (error) {
      console.error("Error fetching user preferences:", error);
    }
  };

  const fetchAllImages = async () => {
    if (!db) {
      console.log("No database connection");
      setLoading(false);
      return;
    }

    setLoading(true);
    console.log("Fetching images...");

    try {
      let imagesList = [];
      const imagesRef = collection(db, "images");
      let publicImagesQuery;

      // Define base conditions for public images
      const publicConditions = [];

      // Apply NSFW filters only to public images
      if (!user || !showNSFW) {
        publicConditions.push(where("isNSFW", "==", false));
      }

      // Build public images query based on feed type
      if (feedType === "top") {
        console.log("Fetching top public images...");
        publicImagesQuery = query(
          imagesRef,
          ...publicConditions,
          orderBy("saveCount", "desc"),
          limit(100)
        );
      } else {
        console.log("Fetching latest public images...");
        publicImagesQuery = query(
          imagesRef,
          ...publicConditions,
          orderBy("timestamp", "desc"),
          limit(100)
        );
      }

      // Fetch public images
      const publicSnapshot = await getDocs(publicImagesQuery);
      const publicImages = await processImages(publicSnapshot.docs);
      imagesList = [...publicImages];

      // If user is logged in, fetch their own images
      if (user) {
        const userImagesQuery = query(
          imagesRef,
          where("userRef", "==", doc(db, "users", user.uid))
        );

        let userImagesSnapshot = await getDocs(userImagesQuery);
        let userImages = await processImages(userImagesSnapshot.docs);

        // If showNSFW is false, filter user's images accordingly
        if (!showNSFW) {
          userImages = userImages.filter((img) => !img.isNSFW);
        }

        // Merge user images with public images, avoiding duplicates
        const existingImageIds = new Set(imagesList.map((img) => img.id));
        const newUserImages = userImages.filter(
          (img) => !existingImageIds.has(img.id)
        );
        imagesList = [...imagesList, ...newUserImages];
      }

      // Optional: Sort imagesList here if necessary

      console.log("Total images fetched:", imagesList.length);

      setImages(imagesList);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  const processImages = async (imageDocs) => {
    const imagesList = [];
    const userRefsSet = new Set();

    imageDocs.forEach((doc) => {
      const imageData = doc.data();
      imagesList.push({
        id: doc.id,
        ...imageData,
        saveCount: imageData.saveCount || 0,
      });
      if (imageData.userRef) {
        userRefsSet.add(imageData.userRef.id);
      }
    });

    // Batch fetch user data
    const userRefs = Array.from(userRefsSet).map((userId) =>
      doc(db, "users", userId)
    );
    const userDocsSnapshots = await Promise.all(
      userRefs.map((userRef) => getDoc(userRef))
    );

    const usernamesMap = {};
    userDocsSnapshots.forEach((userDoc) => {
      if (userDoc.exists()) {
        usernamesMap[userDoc.id] = userDoc.data()?.username || "Unknown User";
      }
    });

    // Attach username to images
    imagesList.forEach((image) => {
      image.username = usernamesMap[image.userRef.id];
    });

    return imagesList;
  };

  const fetchActiveUsers = async () => {
    if (!db) return;

    try {
      const usersRef = collection(db, "users");
      const usersQuery = query(
        usersRef,
        ...(user && showNSFW ? [] : [where("isNSFW", "==", false)])
      );
      const usersSnapshot = await getDocs(usersQuery);

      const usersWithCounts = await Promise.all(
        usersSnapshot.docs.map(async (userDoc) => {
          const userData = userDoc.data();

          // Query images uploaded by the user
          const imagesQuery = query(
            collection(db, "images"),
            where("userRef", "==", userDoc.ref)
          );
          const imagesSnapshot = await getDocs(imagesQuery);

          return {
            id: userDoc.id,
            username: userData.username,
            saveCount: imagesSnapshot.size,
          };
        })
      );

      const filteredUsers = usersWithCounts.filter((user) => user !== null);
      filteredUsers.sort((a, b) => b.saveCount - a.saveCount);
      setActiveUsers(filteredUsers.slice(0, 20)); // Get top 20 active users
    } catch (error) {
      console.error("Error fetching active users:", error);
    }
  };

  const InfoBox = () => (
    <ColorfulPaper elevation={3} sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom fontWeight="bold">
        Welcome to bursts!
      </Typography>
      <Typography variant="h5">
        Using our Chrome browser add-on cultivate &amp; share images you find on the web with the
        world.
      </Typography>
      <Box
        sx={{
          height: "auto",
          borderRadius: "4px",
          marginTop: "32px",
          width: {
            xs: "100%",
            lg: "50%",
          },
        }}
      >
        <img
          src={burstHowTo}
          alt="How to use bursts"
          style={{
            maxWidth: "100%",
            width: "100%",
            height: "auto",
            display: "block",
            borderRadius: "16px",
          }}
        />
      </Box>
      <Typography variant="h6" sx={{ mt: 2 }} fontWeight="bold"></Typography>
      {!user && (
        <LoginButton
          auth={auth}
          sx={{ mt: 2, backgroundColor: "white", color: "black" }}
        />
      )}
    </ColorfulPaper>
  );

  const ImageGrid = () => (
    <>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
        {feedType === "following"
          ? "Following Feed"
          : feedType === "top"
          ? "Top Bursts"
          : "Recent Bursts"}
      </Typography>
      <Paper
        sx={{
          mt: 2,
          p: 3,
          borderRadius: 2,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
        }}
        elevation={0}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <ToggleButtonGroup
            value={feedType}
            exclusive
            onChange={(e, newFeedType) =>
              newFeedType && setFeedType(newFeedType)
            }
            aria-label="feed type"
            sx={{
              "& .MuiToggleButtonGroup-grouped": {
                border: 1,
                borderColor: "divider",
                mx: 1,
                mb: 2,
                borderRadius: 3,
              },
            }}
          >
            <StyledToggleButton value="latest" aria-label="latest">
              Latest
            </StyledToggleButton>
            {user && (
              <StyledToggleButton value="following" aria-label="following">
                Following
              </StyledToggleButton>
            )}
            <StyledToggleButton value="top" aria-label="top">
              Top
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : images.length === 0 ? (
          <Typography>No images found.</Typography>
        ) : (
          <Box>
            <Masonry
              breakpointCols={{
                default: 2,
                700: 1,
              }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {images
                .filter((image) => !failedImages.has(image.id))
                .map((image) => (
                  <Box
                    key={image.id}
                    sx={{
                      mb: 3,
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        overflow: "hidden",
                        borderRadius: 2,
                        backgroundColor: "background.paper",
                        "&:hover": {
                          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.12)",
                        },
                      }}
                    >
                      {image.imageSource ? (
                        <a
                          href={image.imageSource}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={image.imageUrl}
                            alt={image.name || "User image"}
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "4px 4px 0 0",
                              cursor: "pointer",
                            }}
                            loading="lazy"
                            onError={() =>
                              setFailedImages(
                                (prev) => new Set([...prev, image.id])
                              )
                            }
                          />
                        </a>
                      ) : (
                        <img
                          src={image.imageUrl}
                          alt={image.name || "User image"}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "4px 4px 0 0",
                          }}
                          loading="lazy"
                          onError={() =>
                            setFailedImages(
                              (prev) => new Set([...prev, image.id])
                            )
                          }
                        />
                      )}
                      <Box
                        sx={{
                          p: 2,
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: "0.875rem" }}
                          >
                            by{" "}
                            <Link
                              to={`/profile/${
                                image.username || image.userRef.id
                              }`}
                              sx={{
                                color: "primary.main",
                                textDecoration: "none",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {image.username || "Unknown User"}
                            </Link>
                          </Typography>
                          <Box display="flex" alignItems="center">
                            {user && !burstsImages.has(image.id) && (
                              <Link
                                href="#"
                                onClick={(e) => handlebursts(e, image)}
                                sx={{
                                  color: "primary.main",
                                  textDecoration: "none",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                + reburst ({image.saveCount || 0})
                              </Link>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                ))}
            </Masonry>
          </Box>
        )}
      </Paper>
    </>
  );

  const handlebursts = useCallback(
    async (e, image) => {
      e.preventDefault();
      if (!user || !db) return;

      try {
        const imagesRef = collection(db, "images");
        const imageRef = doc(db, "images", image.id);

        // Add a document to the 'saves' collection to record the save action
        const savesRef = collection(db, "saves");
        await addDoc(savesRef, {
          userId: user.uid,
          imageId: image.id,
          timestamp: serverTimestamp(),
        });

        // Atomically increment saveCount
        await updateDoc(imageRef, {
          saveCount: increment(1),
        });

        // Update local state
        setImages((prevImages) =>
          prevImages.map((img) =>
            img.id === image.id
              ? { ...img, saveCount: (img.saveCount || 0) + 1 }
              : img
          )
        );

        setburstsImages((prev) => new Set(prev).add(image.id));
      } catch (error) {
        console.error("Error saving image:", error);
      }
    },
    [user, db]
  );

  const RecentlyActiveSidebar = () => (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
        }}
      >
        Recently Active
      </Typography>
      <Paper
        sx={{
          mt: 2,
          p: 3,
          borderRadius: 2,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
        }}
        elevation={0}
      >
        {activeUsers.map(
          (user) =>
            user.username && (
              <Box
                key={user.id}
                mb={1.5}
                sx={{
                  "&:hover": {
                    backgroundColor: "action.hover",
                    borderRadius: 1,
                  },
                }}
              >
                <Link
                  to={`/profile/${user.username}`}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    display: "block",
                    padding: "8px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{user.username}</span>
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        color: "text.secondary",
                        ml: 1,
                      }}
                    >
                      {user.saveCount} bursts
                    </Typography>
                  </Typography>
                </Link>
              </Box>
            )
        )}
      </Paper>
    </>
  );

  return (
    <Box>
      {!user && <InfoBox />}
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <ImageGrid />
        </Grid>
        <Grid item xs={12} md={3}>
          <RecentlyActiveSidebar />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
