import React from 'react';
import { Button } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function LoginButton({ auth, sx }) {
  const navigate = useNavigate();

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/settings');
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  return (
    <Button color="inherit" onClick={handleLogin} sx={sx}>
      Sign In with Google
    </Button>
  );
}

export default LoginButton;
