import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Masonry from 'react-masonry-css';
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Avatar,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import {
  where,
  collection,
  getDocs,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";

function Profile({ db, user }) {
  const { username: profileUsername } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [uniqueImages, setUniqueImages] = useState([]);
  const [burstsImages, setburstsImages] = useState(new Set());
  const [bio, setBio] = useState("");
  const [isFollowing, setIsFollowing] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [failedImages, setFailedImages] = useState(new Set());

  useEffect(() => {
    fetchUserData();
    if (user && userId) {
      checkFollowStatus();
      fetchFollowersAndFollowing();
    }
  }, [profileUsername, user, userId]);

  const fetchUserData = async () => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("username", "==", profileUsername));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        setUserId(userDoc.id);
        setUsername(userData.username || "");
        setAvatarUrl(userData.avatarUrl || "");
        setBio(userData.bio || "");

        // Check if this is the user's own profile
        setIsOwnProfile(user && user.uid === userDoc.id);

        await fetchUserImages(userDoc.ref);
      } else {
        setErrorMessage("User not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
      setErrorMessage("Failed to load user data. Please try again.");
    }
  };

  const fetchUserImages = async () => {
    try {
      // Fetch saves made by the profile user
      const savesRef = collection(db, "saves");
      const savesQuery = query(
        savesRef,
        where("userId", "==", userId), // Use profile user's ID
        orderBy("timestamp", "desc")
      );
      const savesSnapshot = await getDocs(savesQuery);
      const imageIds = savesSnapshot.docs.map(doc => doc.data().imageId);

      // Fetch images based on image IDs
      const imageDocs = await Promise.all(
        imageIds.map(id => getDoc(doc(db, "images", id)))
      );

      const userImages = [];
      for (let imageDoc of imageDocs) {
        if (imageDoc.exists()) {
          userImages.push({
            id: imageDoc.id,
            ...imageDoc.data(),
          });
        }
      }

      setImages(userImages);
      setUniqueImages(removeDuplicates(userImages));
    } catch (error) {
      console.error("Error fetching user images:", error);
      setErrorMessage("Failed to load user images. Please try again.");
    }
  };

  const removeDuplicates = (images) => {
    const uniqueUrls = new Set();
    return images.filter(image => {
      if (!uniqueUrls.has(image.imageUrl)) {
        uniqueUrls.add(image.imageUrl);
        return true;
      }
      return false;
    });
  };

  const handlebursts = async (e, image) => {
    e.preventDefault();
    if (!user || !db) return;

    try {
      // Reference to the central images collection
      const imagesRef = collection(db, "images");
      const imageQuery = query(imagesRef, where("imageUrl", "==", image.imageUrl));
      const imageSnapshot = await getDocs(imageQuery);

      let imageDocRef;

      if (imageSnapshot.empty) {
        // Image does not exist, add it
        const newImageDoc = await addDoc(imagesRef, {
          ...image,
          timestamp: serverTimestamp(),
          userRef: doc(db, "users", user.uid), // Reference to the user who first added the image
        });
        imageDocRef = newImageDoc;
      } else {
        // Image exists
        imageDocRef = imageSnapshot.docs[0].ref;
      }

      // Add a document to the 'saves' collection to record the save action
      const savesRef = collection(db, "saves");
      await addDoc(savesRef, {
        userId: user.uid,
        imageId: imageDocRef.id,
        timestamp: serverTimestamp(),
      });

      setburstsImages(prev => new Set(prev).add(image.id));
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };

  const checkFollowStatus = async () => {
    if (!user || !userId) return;
    try {
      const followingRef = collection(db, 'users', user.uid, 'following');
      const q = query(followingRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      setIsFollowing(!querySnapshot.empty);
    } catch (error) {
      console.error('Error checking follow status:', error);
    }
  };

  const handleFollow = async () => {
    if (!user || !userId) return;
    try {
      const followingRef = collection(db, 'users', user.uid, 'following');
      if (isFollowing) {
        // Unfollow
        const q = query(followingRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref);
        });
      } else {
        // Follow
        await addDoc(followingRef, {
          userId: userId,
          username: username,
          timestamp: serverTimestamp(),
        });
      }
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  const fetchFollowersAndFollowing = async () => {
    try {
      // Fetch followers
      const followersRef = collection(db, 'users', userId, 'followers');
      const followersSnapshot = await getDocs(followersRef);
      setFollowers(followersSnapshot.docs.map(doc => doc.data()));

      // Fetch following
      const followingRef = collection(db, 'users', userId, 'following');
      const followingSnapshot = await getDocs(followingRef);
      setFollowing(followingSnapshot.docs.map(doc => doc.data()));
    } catch (error) {
      console.error('Error fetching followers/following:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      gap: 3,
      flexDirection: { xs: 'column', md: 'row' } // Stack on mobile, side-by-side on desktop
    }}>
      <Box sx={{ flex: 1 }}>
        {errorMessage && (
          <Typography color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}

        <Paper
          sx={{
            p: 4,
            mb: 4,
            borderRadius: 2,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)'
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              gap: 3,
              mb: 4,
              alignItems: "flex-start",
              flexWrap: { xs: 'wrap', md: 'nowrap' }
            }}
          >
            <Avatar
              src={avatarUrl}
              alt={username}
              sx={{
                width: 120,
                height: 120,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
              }}
            />
            <Box flex={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{ fontWeight: 500 }}
                >
                  {username}
                </Typography>
                {user && user.uid !== userId && (
                  <Button
                    variant={isFollowing ? "outlined" : "contained"}
                    onClick={handleFollow}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      px: 3
                    }}
                  >
                    {isFollowing ? "Unfollow" : "Follow"}
                  </Button>
                )}
              </Box>
              {bio && (
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    color: 'text.secondary'
                  }}
                >
                  {bio}
                </Typography>
              )}
            </Box>
          </Box>

          <Masonry
            breakpointCols={{
              default: 3,
              1100: 2,
              700: 1
            }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {uniqueImages
              .filter(image => !failedImages.has(image.id))
              .map((image) => (
                <Box
                  key={image.id}
                  sx={{
                    mb: 3,
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      overflow: 'hidden',
                      borderRadius: 2,
                      backgroundColor: 'background.paper',
                      '&:hover': {
                        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
                      }
                    }}
                  >
                    {image.imageSource ? (
                      <a href={image.imageSource} target="_blank" rel="noopener noreferrer">
                        <img
                          src={image.imageUrl}
                          alt={image.name || "User image"}
                          style={{
                            width: "100%",
                            height: "auto",
                            display: "block",
                            borderRadius: "8px"
                          }}
                          onError={() => setFailedImages(prev => new Set([...prev, image.id]))}
                        />
                      </a>
                    ) : (
                      <img
                        src={image.imageUrl}
                        alt={image.name || "User image"}
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "block",
                          borderRadius: "8px"
                        }}
                        onError={() => setFailedImages(prev => new Set([...prev, image.id]))}
                      />
                    )}
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                      {user && user.uid !== userId && !burstsImages.has(image.id) && (
                        <Typography
                          component="a"
                          href="#"
                          onClick={(e) => handlebursts(e, image)}
                          sx={{
                            color: 'blue',
                            fontSize: '0.8rem',
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline' },
                          }}
                        >
                          + bursts
                        </Typography>
                      )}
                    </Box>
                  </Paper>
                </Box>
              ))}
          </Masonry>
        </Paper>
      </Box>

      <Paper
        sx={{
          width: { xs: '100%', md: 300 }, // Full width on mobile, 300px on desktop
          p: 2,
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          alignSelf: 'flex-start',
          position: { xs: 'static', md: 'sticky' }, // Only sticky on desktop
          top: 20,
        }}
        elevation={0}
      >
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label={`Followers (${followers.length})`} />
          <Tab label={`Following (${following.length})`} />
        </Tabs>

        <List sx={{ mt: 2 }}>
          {selectedTab === 0
            ? followers.map((follower) => (
                <ListItem key={follower.userId} disablePadding>
                  <ListItemButton
                    component="a"
                    href={`/profile/${follower.username}`}
                  >
                    <ListItemAvatar>
                      <Avatar src={follower.avatarUrl} alt={follower.username} />
                    </ListItemAvatar>
                    <ListItemText primary={follower.username} />
                  </ListItemButton>
                </ListItem>
              ))
            : following.map((followed) => (
                <ListItem key={followed.userId} disablePadding>
                  <ListItemButton
                    component="a"
                    href={`/profile/${followed.username}`}
                  >
                    <ListItemAvatar>
                      <Avatar src={followed.avatarUrl} alt={followed.username} />
                    </ListItemAvatar>
                    <ListItemText primary={followed.username} />
                  </ListItemButton>
                </ListItem>
              ))}
        </List>
      </Paper>
    </Box>
  );
}

export default Profile;
