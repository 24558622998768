import React, { useState, useEffect } from "react";
import {
  Alert,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Avatar,
  Snackbar,
} from "@mui/material";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link as RouterLink } from "react-router-dom";
import { getAuth } from "firebase/auth";

const resizeImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 250;
        const scaleFactor = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleFactor;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
};

function Settings({ user }) {
  const [username, setUsername] = useState("");
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscriptionLevel, setSubscriptionLevel] = useState(0);
  const [error, setError] = useState(null);
  const [isProfileNSFW, setIsProfileNSFW] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [bio, setBio] = useState("");
  const [bioSaved, setBioSaved] = useState(false);
  const [showNSFW, setShowNSFW] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  const stripeCustomerPortalUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL
      : process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL_TEST;

  const stripePurchaseUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_PURCHASE_URL
      : process.env.REACT_APP_STRIPE_PURCHASE_URL_TEST;

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUsername(userData.username || "");
        setSubscriptionLevel(userData.subscriptionLevel || 0);
        setIsProfileNSFW(userData.isNSFW || false);
        setAvatarUrl(userData.avatarUrl || "");
        setBio(userData.bio || "");
        setShowNSFW(userData.showNSFW || false);

        // Check if auth token exists, if not generate and save it
        if (userData.authToken) {
          setAuthToken(userData.authToken);
        } else {
          const newToken = generateAuthToken();
          await setDoc(
            doc(db, "users", user.uid),
            { authToken: newToken },
            { merge: true }
          );
          setAuthToken(newToken);
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleUsernameSubmit = async () => {
    try {
      if (username.trim()) {
        // Check if the username already exists (only if it has changed)
        const currentUserDoc = await getDoc(doc(db, "users", user.uid));
        const currentUsername = currentUserDoc.data()?.username;

        if (username.trim() !== currentUsername) {
          const usernameQuery = await getDocs(
            query(
              collection(db, "users"),
              where("username", "==", username.trim())
            )
          );

          if (!usernameQuery.empty) {
            setError("Username already exists. Please choose a different one.");
            return;
          }
        }
      }

      // Save username (if changed) and NSFW settings
      await setDoc(
        doc(db, "users", user.uid),
        {
          ...(username.trim() && { username: username.trim() }),
          isNSFW: isProfileNSFW,
        },
        { merge: true }
      );
      setIsEditingUsername(false);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error("Error updating user settings:", error);
      setError("Failed to update settings. Please try again.");
    }
  };

  const handleSubscribe = () => {
    window.open(stripePurchaseUrl, "_blank", "noopener,noreferrer");
  };

  const handleNSFWToggle = async (event) => {
    const newValue = event.target.checked;
    setIsProfileNSFW(newValue);
    try {
      await setDoc(
        doc(db, "users", user.uid),
        { isNSFW: newValue },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating NSFW setting:", error);
      setError("Failed to update NSFW setting. Please try again.");
    }
  };

  const handleShowNSFWToggle = async (event) => {
    const newValue = event.target.checked;
    setShowNSFW(newValue);
    try {
      await setDoc(
        doc(db, "users", user.uid),
        { showNSFW: newValue },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating show NSFW setting:", error);
      setError("Failed to update show NSFW setting. Please try again.");
    }
  };

  const handleAvatarChange = (event) => {
    if (event.target.files[0]) {
      setAvatar(event.target.files[0]);
    }
  };

  const [avatarUploading, setAvatarUploading] = useState(false);

  const handleAvatarUpload = async () => {
    if (!avatar) return;

    try {
      setAvatarUploading(true); // Start loading

      // Resize the image before uploading
      const resizedImage = await resizeImage(avatar);

      const storageRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(storageRef, resizedImage);
      const downloadUrl = await getDownloadURL(storageRef);

      await setDoc(
        doc(db, "users", user.uid),
        { avatarUrl: downloadUrl },
        { merge: true }
      );

      setAvatarUrl(downloadUrl);
      setAvatar(null);
      setError(null);
    } catch (error) {
      console.error("Error uploading avatar:", error);
      setError("Failed to upload avatar. Please try again.");
    } finally {
      setAvatarUploading(false); // End loading
    }
  };

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  const handleBioSubmit = async () => {
    try {
      await setDoc(
        doc(db, "users", user.uid),
        { bio: bio.trim() },
        { merge: true }
      );
      setError(null);
      setBioSaved(true); // Set bioSaved to true when successful
    } catch (error) {
      console.error("Error updating bio:", error);
      setError("Failed to update bio. Please try again.");
    }
  };

  const handleBioSavedClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setBioSaved(false);
  };

  const generateAuthToken = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < 12; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  };

  const [isEditingBio, setIsEditingBio] = useState(false);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: 600 }}>
        Settings
      </Typography>
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: 2,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Alert severity="info" sx={{ mb: 2 }}>
          <strong>First things first!</strong> Install the{" "}
          <RouterLink
            to="https://chromewebstore.google.com/detail/burstsus-save-images-you/jknmfmfnffjficmjcokjbjedfjnifpfh?authuser=0&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Extension
          </RouterLink>{" "}
          to save images. Use your authentication token below to sign in.
        </Alert>
        <Box display="flex" alignItems="start" gap={2}>
          <Box display="flex" flex={1} gap={2}>
            <Avatar
              src={avatarUrl}
              alt={username || user.displayName || "User"}
              sx={{ width: 100, height: 100 }}
            />
            <Box flex={1}>
              <Typography variant="h6" gutterBottom>
                Welcome, {username || user.displayName || "User"}!
              </Typography>

              {/* Bio section */}
              {isEditingBio ? (
                <Box sx={{ mt: 1, mb: 2 }}>
                  <TextField
                    value={bio}
                    onChange={handleBioChange}
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    size="small"
                  />
                  <Box sx={{ mt: 1 }}>
                    <Button
                      onClick={() => {
                        handleBioSubmit();
                        setIsEditingBio(false);
                      }}
                      variant="contained"
                      size="small"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => setIsEditingBio(false)}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ mt: 1, mb: 2 }}>
                  <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                    {bio || "No bio yet"}
                  </Typography>
                  <Button
                    onClick={() => setIsEditingBio(true)}
                    size="small"
                    sx={{ mt: 0.5, p: 0 }}
                  >
                    Edit bio
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box mt={2}>
          {isEditingUsername ? (
            <Box mb={2}>
              <TextField
                label="Username"
                value={username}
                onChange={handleUsernameChange}
                variant="outlined"
                size="small"
              />
              <Button
                onClick={handleUsernameSubmit}
                variant="contained"
                sx={{ ml: 1 }}
              >
                Save
              </Button>
            </Box>
          ) : (
            <Box mb={2}>
              <Button
                onClick={() => setIsEditingUsername(true)}
                variant={username ? "outlined" : "contained"}
                sx={{ backgroundColor: username ? "auto" : "red" }}
                size="small"
              >
                {username ? "Change Username" : "Set Username"}
              </Button>
            </Box>
          )}

          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="avatar-upload"
              type="file"
              onChange={handleAvatarChange}
            />
            <label htmlFor="avatar-upload">
              <Button variant="outlined" component="span" size="small">
                Change Avatar
              </Button>
            </label>
            {avatar && (
              <Button
                onClick={handleAvatarUpload}
                variant="contained"
                size="small"
                sx={{ ml: 1 }}
                disabled={avatarUploading}
              >
                {avatarUploading ? (
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                ) : (
                  "Upload"
                )}
              </Button>
            )}
          </Box>
        </Box>
        {username && (
          <Box mt={2}>
            <Button
              component={RouterLink}
              to={`/profile/${username}`}
              variant="contained"
              color="primary"
              size="small"
            >
              View your public profile
            </Button>
          </Box>
        )}

        <Paper
          elevation={0}
          sx={{
            p: 3,
            mt: 5,
            mb: 5,
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
            backgroundColor: "grey.200",
          }}
        >
          <Box>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={isProfileNSFW}
                    onChange={handleNSFWToggle}
                    color="secondary"
                  />
                }
                label="Mark profile as NSFW"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={showNSFW}
                    onChange={handleShowNSFWToggle}
                    color="secondary"
                  />
                }
                label="Show NSFW content"
              />
            </Box>
          </Box>
        </Paper>
        {/* <Box mt={2}>
          <Typography variant="body1" gutterBottom>
            <Box>
              Subscription Level: {subscriptionLevel === 1 ? "Premium" : "Free"}
            </Box>
            {subscriptionLevel > 0 && (
              <RouterLink to={stripeCustomerPortalUrl}>
                Manage Subscription
              </RouterLink>
            )}
          </Typography>
          {subscriptionLevel === 0 && (
            <>
              <Alert severity="info" sx={{ mb: 2 }}>
                Upgrade to premium to unlock all the features! Just $3/mo.
              </Alert>
              <Button
                onClick={handleSubscribe}
                variant="contained"
                color="primary"
                sx={{ mt: 1 }}
              >
                Subscribe to Premium
              </Button>
            </>
          )}
        </Box> */}
      </Paper>
      {authToken && (
        <Paper
          elevation={0}
          sx={{
            p: 3,
            mt: 3,
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Authentication Token
          </Typography>
          <Typography variant="body2" gutterBottom>
            This is your authentication token for the{" "}
            <RouterLink
              to="https://chromewebstore.google.com/detail/burstsus-save-images-you/jknmfmfnffjficmjcokjbjedfjnifpfh?authuser=0&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome Extension
            </RouterLink>
            . Keep it secure!
          </Typography>
          <Box
            sx={{
              p: 2,
              bgcolor: "grey.100",
              borderRadius: 1,
              fontFamily: "monospace",
              mt: 2,
            }}
          >
            {authToken}
          </Box>
        </Paper>
      )}
      {authToken && (
        <Paper
          elevation={0}
          sx={{
            p: 3,
            mt: 3,
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            ❓ Wait, how does bursts work?
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            It's simple! After installing the Chrome extension:
          </Typography>
          <ol>
            <Typography component="li" sx={{ mb: 1 }}>
              Browse the web like you normally would
            </Typography>
            <Typography component="li" sx={{ mb: 1 }}>
              When you see an image you like, click the extension icon in your
              browser
            </Typography>
            <Typography component="li" sx={{ mb: 1 }}>
              A blue "Burst" button will appear over the image
            </Typography>
            <Typography component="li">
              Click the button, and the image will be automatically saved to
              your bursts profile!
            </Typography>
          </ol>
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you don't see the blue button, you may be on a site where they're
            using a background image, or some other means of embedding images.
            That said, the extension should work on most sites.
          </Typography>
        </Paper>
      )}
      <Snackbar
        open={bioSaved}
        autoHideDuration={3000}
        onClose={handleBioSavedClose}
        message="Bio saved successfully"
      />
    </Box>
  );
}

export default Settings;
