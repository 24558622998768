import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LoginButton from "./components/LoginButton";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import PaymentSuccess from "./PaymentSuccess";
import Footer from "./Footer";
import Terms from "./Terms";
import Privacy from "./Privacy";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Home from "./Home";
import Profile from "./Profile";
import About from "./About";
import { HelmetProvider } from "react-helmet-async";
import burstsLogo from "./images/bursts-128.png";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <CircularProgress />; // Or a loading spinner
  }

  return (
    <HelmetProvider>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <AppBar position="static" sx={{ backgroundColor: "#000" }}>
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img
                src={burstsLogo}
                alt="bursts Logo"
                style={{ height: "30px", marginRight: "10px" }}
              />
              <Typography
                variant="h6"
                component={Link}
                to="/"
                sx={{ color: "white", textDecoration: "none" }}
              >
                bursts
              </Typography>
            </Box>
            {user ? (
              <>
                <Button color="inherit" component={Link} to="/dashboard">
                  Dashboard
                </Button>
                <IconButton color="inherit" component={Link} to="/settings">
                  <SettingsIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={() => auth.signOut()}
                  aria-label="Sign Out"
                >
                  <LogoutIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/about">
                  About
                </Button>
                <LoginButton auth={auth} />
              </>
            )}
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" sx={{ flex: 1 }}>
          <Box sx={{ my: 4 }}>
            <Routes>
              <Route
                path="/"
                element={<Home storage={storage} user={user} db={db} />}
              />
              <Route
                path="/dashboard"
                element={
                  user ? (
                    <Dashboard user={user} storage={storage} db={db} />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route path="/settings" element={<Settings user={user} />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/profile/:username"
                element={<Profile db={db} user={user} />}
              />
              <Route path="/about" element={<About />} />
            </Routes>
          </Box>
        </Container>
        <Footer />
      </Box>
    </HelmetProvider>
  );
}

export default App;
