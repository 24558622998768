import React from 'react';
import { Typography, Box, Paper, Grid, Divider } from '@mui/material';
import FoundImg from './images/old-ffffound.png';

function About() {
  return (
    <Box sx={{ p: 3, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h3" gutterBottom>
        About bursts
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3, pb: 1 }}>
        <Typography paragraph>
          <strong>bursts</strong> is a modern reimagining of a beloved platform that once captured the hearts of creatives and explorers across the internet. With our Chrome addon, you can effortlessly save and curate inspiring images you discover online.
        </Typography>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Inspired by FFFFOUND!
            </Typography>
            <Typography paragraph>
              FFFFOUND! was launched in 2007 by Yosuke Abe and Keita Kitamura of the Japanese company Tha, owned by Yugo Nakamura. For a decade, it stood as a beacon of creativity and discovery until its closure on May 15, 2017.
            </Typography>
            <Typography paragraph>
              What set FFFFOUND! apart was its unique recommendation system. Users could 'like' images, and based on these interactions, the platform would provide personalized image recommendations. This feature fostered a sense of serendipitous discovery that many users found addictive and inspiring.
            </Typography>
            <Typography paragraph>
            FFFFOUND! was known for its invitation-only registration system. This exclusivity helped maintain a high quality of content and fostered a tight-knit community of creative professionals and enthusiasts.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Its Impact and Influence
            </Typography>
            <Typography paragraph>
              The impact of FFFFOUND! extended beyond its own platform. It inspired other image-sharing services, such as We Heart It, which was created by Brazilian web designer Fabio Giolito in response to FFFFOUND!'s limited registration system.
            </Typography>
            <Typography paragraph>
              As one of FFFFOUND!'s devoted users, I felt the loss deeply when it shut down. The platform had been a source of inspiration and connection, and its absence left a noticeable gap in my online experience.
            </Typography>
            <Typography paragraph>
              For years, I yearned for something similar to return, to recreate that sense of discovery and shared appreciation for visual art.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <img src={FoundImg} alt="bursts" style={{ marginTop: '60px', width: '100%', marginBottom: '20px' }} />

      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        The Birth of Bursts
      </Typography>
      <Typography paragraph>
        Driven by nostalgia and a desire to restore a piece of the internet that had been lost, I built bursts. This platform aims to recapture the spirit of the original while embracing modern web technologies.
      </Typography>
      <Typography paragraph>
        Our new bursts platform aims to recreate the magic of the original while making it more accessible. We've maintained the focus on high-quality, inspiring imagery, but removed the barrier of invitation-only registration. Our goal is to foster a larger, yet equally passionate community of visual enthusiasts.
      </Typography>
      <Typography paragraph>
        With bursts, we invite you to join us in creating a new community of image enthusiasts, where you can once again save, share, and discover inspiring visuals from across the web.
      </Typography>

      <Typography variant="caption" display="block" sx={{ mt: 3 }}>
        Source: <a href="https://en.wikipedia.org/wiki/FFFFOUND!" target="_blank" rel="noopener noreferrer">FFFFOUND! Wikipedia Page</a>
      </Typography>
    </Box>
  );
}

export default About;
